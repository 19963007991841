import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import aboutStart from '../layout/svg-files/about.svg'
import aboutEnd from '../layout/svg-files/contact.svg'
import './about.css'
import Finn from './finn.png'
const About = () => {

    return (
        <div className="grid-container" id="about">
    <h1 className="left"><HashLink smooth to="/#" id="about-link"><object data={aboutStart}/>&lt;About/&gt;</HashLink></h1>
  <div className="grid-about-inner-container" id="about-inner">
    <div className="column-img">
      <img src={Finn}/>
      <Link to="https://docs.google.com/document/d/1fbTJLOcouGsRbJJ57zGlEpl6geb9lAQZ/edit?usp=sharing&ouid=101540810288948244507&rtpof=true&sd=true" target="_blank" ><button type="button">Resume</button></Link>
    </div><div className="about-paragraph">
       I've always had a life long passion for IT, I picked programming up in high school and here we are...<br/>
      This website (and it's domains) are hosted locally off a raspberry pi running Debian 11 with NGINX as a server<br/>
      
      </div>
      
    </div>
    <h1 className="right"><HashLink smooth to="/#" id="about-link">&lt;About/&gt; <object data={aboutEnd}/></HashLink></h1>
  </div>

    )
}

export default About;