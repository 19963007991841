import React from 'react'
import { HashLink } from 'react-router-hash-link'
import skillsStart from '../layout/svg-files/skills.svg'
import skillsEnd from '../layout/svg-files/projects.svg'
import './skills.css'
const Skills = () => {

    return (
        <div className="grid-container" id="skills" >
            <h1 className="left"><HashLink smooth to="/#" id="skills-link"><object data={skillsStart}/>&lt;Skills&gt;</HashLink></h1>
                <div className="grid-skills-inner-container" id="skills-inner">                
                <h3><span id='skill-subheading'>.frontend </span>&#123;<br/>javascript<br/>react<br/>redux<br/>css3<br/>html5<br/>bootstrap<br/>&#125;</h3>
                <h3><span id='skill-subheading'>.backend </span>&#123;<br/>c#<br/>.net<br/>node.js<br/>&#125;</h3>
                <h3><span id='skill-subheading'>.tools </span>&#123;<br/>git<br/>aws<br/>webpack<br/>&#125;</h3>
                <h3><span id='skill-subheading'>.systems </span>&#123;<br/>linux<br/>windows<br/>&#125;</h3>
                </div>
            <h1 className="right"><HashLink smooth to="/#" id="skills-link">&lt;Skills/&gt;<object data={skillsEnd}/></HashLink></h1>
  </div>

    )
}

export default Skills;