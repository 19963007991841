import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import projectsStart from '../layout/svg-files/projects.svg'
import projectsEnd from '../layout/svg-files/about.svg'
import beerLabLogo from './images/beer-lab-logo.png'
import './projects.css'

const Projects = () => {

  return (
    <div className="grid-container" id="projects">
      <h1 className="left"><HashLink smooth to="/#" id="projects-link"><object data={projectsStart}/> &lt;Projects&gt;</HashLink></h1>
      <div className="grid-projects-inner-container" id="projects-inner">
        <div className="column">
          <div className="card">
            <img src={beerLabLogo} alt="The Beer Lab logo" max-width="260" max-height="120" />
            <div className="container">
            <Link to="https://beerlab.finn-mccarthy.com" target="_blank" ><button type="button">Live App</button></Link>
              <Link to="https://github.com/FinnMac/The-Beer-Lab" target="_blank" ><button type="button">Source Code</button></Link>
              <h4 ><label className="heading-beerlab">The Beer Lab</label>
              </h4>
              <p>The Beer Lab is a project aimed at educating the general public on beer, and its production, among other things. Featuring a real time API, a database, and a user system</p>
              <br/></div>
          </div>
        </div>
        <div className="column">
          {/* Second project */}
        </div>
      </div>
      <h1 className="right"><HashLink smooth to="/#" id="projects-link">&lt;Projects/&gt; <object data={projectsEnd}/></HashLink></h1>
    </div>

  )
}

export default Projects;