import React from 'react'
import Landing from '../section-one/Landing'
import Skills from '../section-skills/Skills'
import Contact from '../section-contact/Contact'
import Projects from '../section-projects/Projects'
import About from '../section-about/About'
import Header from './Header'

const Main = () => {

    return (<>
            <Header/>
        <main>
<Landing/>
<Skills/>
<Projects/>
<About/>
<Contact/>
        </main></>
            )
}

export default Main;