import React from 'react'
import { BrowserView, MobileView } from 'react-device-detect';
import Icon from './menu_ico.svg'
import { HashLink } from 'react-router-hash-link'
import skills from '../layout/svg-files/skills.svg'
import projects from '../layout/svg-files/projects.svg'
import about from '../layout/svg-files/about.svg'
import contact from '../layout/svg-files/contact.svg'

import './landing.css'
const Landing = () => {

    return (
        <div className="grid-sect-1-container">

  <div className="grid-menu-container">
    <object className="menu" data={Icon} />
    <h1>
        <div style={{display: 'inline-block'}}>
            <h1 className="menu-upper">Hi, I'm Finn.</h1>
        </div><br/>
        <div style={{display: 'inline-block'}}>
            <h1 className="menu-lower">I'm a full stack&#x2004;</h1>
            <MobileView/>
        </div>
        <div style={{display: 'inline-block'}}>
            <h1 className="menu-lower-two">developer</h1>
        </div>
    </h1>
  </div>
        <div className="grid-submenu-container">
  <h1 className="main-link"><HashLink smooth to="/#skills" id="skills-link"><object data={skills}/>Skills</HashLink></h1>
  <h1 className="main-link"><HashLink smooth to="/#projects" id="projects-link"><object data={projects}/>Projects</HashLink></h1>
  <h1 className="main-link"><HashLink smooth to="/#about" id="about-link"><object data={about}/>About</HashLink></h1>
  <h1 className="main-link"><HashLink smooth to="/#contact" id="contact-link"><object data={contact}/>Contact</HashLink></h1></div>
</div>

    )
}

export default Landing;