import React from 'react'
import { HashLink } from 'react-router-hash-link'
import contactStart from '../layout/svg-files/contact.svg'
import contactEnd from './../layout/svg-files/skills.svg'
import './contact.css'
const Contact = () => {

    return (
        <div className="grid-container" id="contact">
  <h1 className="left"><HashLink smooth to="/#" id="contact-link"><object data={contactStart}/> &lt;Contact&gt;</HashLink></h1>
  <div className="grid-contact-inner-container" id="contact-inner">
    <form>
    <div>
        <input
        type="text"
        placeholder="Name"/>
    </div>
    <div>
        <input
        type="email"
        placeholder="Email"/></div>
    <div>
        <textarea
        type="text"
        placeholder="Message"
        rows="5"
        cols="22"/>
        
    </div>
    <div>
    <input type="submit" className="submit-button" value="Send"/>
    </div>
    </form>
  </div>

  <h1 className="right"><HashLink smooth to="/#" id="contact-link">&lt;Contact/&gt; <object data={contactEnd} /></HashLink></h1>
  </div>

    )
}

export default Contact;