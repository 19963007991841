import React from "react";
import { Link } from "react-router-dom";
import './layout.css'
import github from './svg-files/github.svg'
import linkedin from './svg-files/linkedin.svg'
import file from './svg-files/file.svg'

const Header = () => {
    return (
        <header>
            <div className="nav">
                <Link to="https://github.com/FinnMac/" target="_blank">
                <span id="github" ></span>
                </Link>
                <Link to="https://www.linkedin.com/in/finn-mccarthy-83276b206/" target="_blank">
                <span id="linkedin"></span>
                </Link>
                <Link to="https://docs.google.com/document/d/1fbTJLOcouGsRbJJ57zGlEpl6geb9lAQZ/edit?usp=sharing&ouid=101540810288948244507&rtpof=true&sd=true" target="_blank">
                <span id="file"></span>
                </Link>
            </div>
        </header>
    )
}

export default Header;